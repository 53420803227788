<template>
  <div>
    <div class="google-map" ref="googleMap"></div>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';

const GOOGLE_MAP_API_KEY = process.env.VUE_APP_API_GOOGLE_KEY;

export default {
  name: 'GoogleMapMarkers',
  props: [
    'items',
    'isVisible',
    'maxZoom',
  ],
  data() {
    return {
      map: null,
      google: null,
      loader: null,
      infoWindow: null,
      markers: [],
      options: {
        center: {
          lat: 0,
          lng: 0,
        },
        zoom: 3,
      },
      bounds: {},
    };
  },
  mounted() {
    this.google = new Loader({
      apiKey: GOOGLE_MAP_API_KEY,
      version: 'weekly',
      libraries: ['places', 'drawing'],
    });
    this.initializeMap();
  },
  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap;
      this.google
        .load()
        .then((google) => {
          this.google = google;
          this.map = new google.maps.Map(mapContainer, this.options);
          this.infoWindow = new google.maps.InfoWindow();
          this.setMarkers(this.items);
          this.setMapOnAll(this.map);
        })
        .catch(() => {
          console.log('Google maps error');
        });
    },

    setMarkers(items) {
      const bounds = new this.google.maps.LatLngBounds();

      if (this.markers.length > 0) {
        this.setMapOnAll(null);
        this.markers = [];
      }
      items.forEach(({
        lat, lon, name, id,
      }) => {
        if (lat === null || lon === null) {
          return;
        }
        const marker = new this.google.maps.Marker({
          position: {
            lat,
            lng: lon,
          },
          title: `${id}. ${name}`,
        });

        marker.addListener('click', () => {
          this.infoWindow.close();
          this.infoWindow.setContent(marker.getTitle());
          this.infoWindow.open(marker.getMap(), marker);
        });
        bounds.extend(marker.getPosition());
        this.markers.push(marker);
      });
      this.map.fitBounds(bounds);

      if (this.maxZoom) {
        setTimeout(() => {
          const zoom = this.map.getZoom();
          if (zoom > this.maxZoom) {
            this.map.setZoom(this.maxZoom);
          }
        }, 500);
      }
    },

    setMapOnAll(map) {
      for (let i = 0; i < this.markers.length; i += 1) {
        this.markers[i].setMap(map);
      }
    },
  },
  watch: {
    isVisible(val) {
      if (val) {
        this.setMarkers(this.items);
        this.setMapOnAll(this.map);
      }
    },
    items(val) {
      this.setMarkers(val);
      this.setMapOnAll(this.map);
    },
  },
};
</script>

<style scoped>
.google-map {
  width: 100%;
  min-height: 400px;
  height: 500px;
}
</style>
