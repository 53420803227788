<template>
  <xlsx-workbook>
    <xlsx-sheet
      :collection="sheet.data"
      v-for="sheet in sheets"
      :key="sheet.name"
      :sheet-name="sheet.name"
    />
    <xlsx-download
      :filename="filename"
      disable-wrapper-click
    >
      <template #default="{download}">
        <slot>
          <v-btn
            @click="download"
            class="mx-2"
            color="cyan darken-3"
            dark
            fab
            small
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <slot>
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    dark
                  >
                    mdi-file-export
                  </v-icon>
                </slot>
            </template>
            export summary to xlsx
          </v-tooltip>
        </v-btn>
        </slot>
      </template>
    </xlsx-download>
  </xlsx-workbook>
</template>

<script>

import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue-xlsx';

export default {
  name: 'XlsxExport',
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  props: {
    sheets: {
      type: Array,
      default() {
        return [{}];
      },
    },
    filename: {
      type: String,
      default: 'export.xlsx',
    },
  },
  data: () => ({
  }),

};
</script>
