<template>
  <v-container>
    <v-dialog
      v-model="geotagDestinationDialog.isOpened"
      max-width="400px"
    >
      <v-card>
        <v-card-title>
          {{ `${geotagDestinationDialog.geotagName}(${geotagDestinationDialog.geotagId})` }}
          route map
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <tbody>
            <tr>
              <td>Placenames ID</td>
              <td>{{ geotagDestinationDialog.placenamesIds.join(', ') }}</td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{{ geotagDestinationDialog.routeName.join(', ')}}</td>
            </tr>
            <tr>
              <td>IATA/ICAO</td>
              <td>{{ geotagDestinationDialog.iataIcao.join(', ') }}</td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="closeGeotagDestinationDialog"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row
      dense align="center"
      justify="center"
      class="mt-4"
    >
      <div
          style="width: 150px"
          class="mr-10"
      >
        <v-select
            label="Customer"
            :items="tripbitsCustomerList"
            v-model="currentTripbitsCustomer"
            item-text="label"
            item-value="id"
            return-object
            @change="changeFilters"
        />
      </div>

      <div
          class="mr-10"
      >
        <v-btn
            :color="!isFiltersChanged ? 'info' : 'warning' "
            class="ma-2"
            @click="getCustomerStatisticsData"
            :outlined="!isFiltersChanged"
        >
          {{ isFiltersChanged ? 'Update' : 'Updated ' }}
        </v-btn>

      </div>

      <div
          v-if="tripbitsCustomers.length"
      >
        Tripbits info:
        <div
            v-for="(customer, key) in tripbitsCustomers"
            :key="key"
        >
          <span class="font-weight-bold">{{ customer.name }}</span>
          {{ ` ${customer.full_name}(ID: ${customer.id},
           RM: ${customer.routemap ? customer.routemap : "" })` }}
        </div>
      </div>
      <v-spacer></v-spacer>
      <XlsxExport
        v-if="!hasAllAbilities([$constants.ABILITY.CUSTOMER])"
        :filename="getExportSummaryWorkbook().filename"
        :sheets="getExportSummaryWorkbook().sheets"
      />

    </v-row>

    <v-row
      class="mb-3">
      <v-col>
        <v-tabs
          v-model="currentCustomerTab"
        >
          <v-tab
            v-for="(tab, key) in customerTabs"
            :key="key"
          >
            {{ tab }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-tabs-items
      v-model="currentCustomerTab"
    >
      <v-tab-item
        :key="0"
      >
        <v-row
          v-if="customerHeadBars.length"
        >
          <v-col
            v-for="(bar, index) in customerHeadBars"
            :cols="Math.floor(12/customerHeadBars.length)"
            :key="index"
          >
            <GreyBarWithTitleAndValue
            :title = bar.title
            :value = bar.value
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            :cols="6"
          >
            <GoogleMapMarkers
              :items="geotagsOnMap"
              :isVisible="true"
            />
          </v-col>
          <v-col
            :cols="6"
          >
            Priority Destination Coverage
            <v-data-table
              :headers="routeMapTable.headers"
              :items="routeMapTableItemsWithTotal"
              :disable-sort="true"
              :expanded.sync="routeMapTable.expanded"
              :disable-pagination="true"
              :disable-filtering="true"
              :hide-default-footer="true"
              item-key="rating_option"
              @click:row="rowClick"
              show-expand
              show-select
            >
              <template v-slot:top>
                <v-switch
                  v-if="!hasAllAbilities([$constants.ABILITY.CUSTOMER])"
                  v-model="routeMapTable.isShowMaintenanceHeaders"
                  label="Extended view"
                  class="pa-3"
                ></v-switch>
              </template>

              <template v-slot:header.data-table-select="{ }">
                <v-simple-checkbox
                  :ripple="false"
                  :value="checkIfAllRatingOptionSelected()"
                  @input="selectAllRatingsToggle($event)"
                ></v-simple-checkbox>
              </template>

              <template v-slot:item.data-table-select="{ item }">
                <v-simple-checkbox
                  v-if="item.rating_option !== 'total'"
                  :ripple="false"
                  :value="checkIfRatingOptionSelected(item.rating_option)"
                  @input="selectAllRatingRoutesToggle($event, item.rating_option)"
                ></v-simple-checkbox>
              </template>

              <template v-slot:item.category="{ item }">
                {{ item.manual_name }}
                {{ item.route_based_name.length ? `(${item.route_based_name})` : '' }}
              </template>

              <template v-slot:item.coverage="{ item }">
                {{ `${item.coverage}%` }}
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-data-table
                    :headers="routeMapTableExpandHeaders"
                    :items="getRoutesForRatingOption(item.rating_option)"
                    :disable-sort="true"
                    :disable-filtering="true"
                    show-select
                  >

                    <template v-slot:header.data-table-select="{ }">
                      <v-simple-checkbox
                        :ripple="false"
                        :value="checkIfAllRoutesForRatingOptionSelected(item.rating_option)"
                        @input="selectAllRatingRoutesToggle($event, item.rating_option)"
                      ></v-simple-checkbox>
                    </template>

                    <template v-slot:item.data-table-select="{ item }">
                      <v-simple-checkbox
                        :ripple="false"
                        :value="item.is_selected"
                        @input="updateSelectedRoutes($event, item)"
                      ></v-simple-checkbox>
                    </template>

                    <template v-slot:item.pn_ids="{ item }">
                      {{ item.pn_ids.join(', ') }}
                    </template>

                    <template v-slot:item.iata_icao="{ item }">
                      <div v-for="(el, index) in item.iata_icao" :key="index">
                        {{ el }}
                      </div>
                    </template>

                    <template v-slot:item.geotags="{ item }">
                      <div v-for="(el, index) in item.geotags" :key="index">
                        {{ el }}
                      </div>
                    </template>

                    <template v-slot:item.tb_ids="{ item }">
                      {{ item.tb_ids.join(', ') }}
                    </template>

                  </v-data-table>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            :cols="4"
          >
            <SimpleList
              label="Reviews"
              :emphasized-text="customerContent.reviewsTotalAmount"
              :main-list="customerContent.providerRating"
            />
          </v-col>
          <v-col
            :cols="4"
          >
            <SimpleList
              label="Hashtags"
              :emphasized-text="customerContent.poiHashtagsTotalAmount"
              :main-list="customerContent.hashtagRating"
            />
          </v-col>
          <v-col
            :cols="4"
          >
            <SimpleList
              label="Guides"
              :emphasized-text="customerContent.geotagHashtagsTotalAmount"
              :main-list="customerContent.hashtagGeotagRating"
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item
        :key="1"
      >
        <v-row>
          <v-col
            cols="6"
          >
          </v-col>
          <v-col
            cols="6"
          >
            <Chart
              :chart-data="geotagCharts"
              :options="chartOptions"
              :width="500"
              :height="150"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :options.sync="table.options"
              :footer-props="{ itemsPerPageOptions: [25, 50, 100, 200 -1] }"
              :headers="geotagsTableHeaders"
              :items="geotagsTotal"
              loading-text="Loading... Please wait"
              :single-expand="true"
            >
              <template v-slot:body.prepend>
                <tr class="filter-row">
                  <td>
                    <v-text-field
                      :value="geotagFilters.id"
                      @input="v=>geotagFilters.id=v"
                      type="number"
                      label="id"
                      clearable
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      :value="geotagFilters.name"
                      @input="v=>geotagFilters.name=v"
                      type="text"
                      label="geotag"
                      clearable
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      :valuel="geotagFilters.pois_count"
                      @input="v=>geotagFilters.pois_count=v"
                      type="number"
                      label="pois"
                      clearable
                      dense
                    />
                  </td>
                  <td>
                    <v-autocomplete
                      ref="statusselect"
                      v-model="geotagFilters.status"
                      :items="['off', 'prev', 'prev only', 'live']"
                      label="status"
                      dense
                      chips
                      small-chips
                      deletable-chips
                      :menu-props="{
                          closeOnContentClick: true,
                        }"
                    />
                  </td>
                  <td>
                    <v-autocomplete
                      ref="popularstatusselect"
                      v-model="geotagFilters.is_customer_popular"
                      :items="['on','off']"
                      label="popular"
                      dense
                      chips
                      small-chips
                      deletable-chips
                      :menu-props="{
                          closeOnContentClick: true,
                        }"
                      :disabled="!!tripbitsCustomers.length || tripbitsCustomers[0].is_ground"
                    />
                  </td>
                </tr>
              </template>

              <template v-slot:item.id="{ item }">
                {{ item.id }}
                <v-btn
                  v-if="isGeotagRoutemapDestination(item.id)"
                  class="ml-10"
                  icon
                  @click="openGeotagDestinationDialog(item.id, item.name)"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <route-svg
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    show route map
                  </v-tooltip>
                </v-btn>
              </template>

              <template v-slot:item.name="{ item }">
                <span>{{ item.name }}</span>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      :to="`../geotag/${item.id}`" target="_blank"
                      icon
                      small
                    >
                      <v-icon color="orange" dense>
                        mdi-pen
                      </v-icon>
                    </v-btn>
                  </template>
                  geotag page
                </v-tooltip>
              </template>

              <template v-slot:item.pois_count="{ item }">
                <div><span class="font-italic">total</span>: {{ item.pois_count }}</div>
                <span class="font-italic">enabled</span>:
                <span class=" green--text">{{ item.pois_enabled_count }}</span>
              </template>

              <template v-slot:item.status="{ item }">
                <TripleButton
                  :status="item.status"
                  :disabled="true"
                />
              </template>

              <template v-slot:item.popular="{ item }">
                <v-switch
                  v-if="!(tripbitsCustomers.length || tripbitsCustomers[0].is_ground)"
                  v-model="item.customers[0].is_popular"
                  :value="false"
                  disabled
                />
                <v-tooltip
                  v-else
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-switch
                      v-bind="attrs"
                      v-on="on"
                      :value="false"
                      disabled
                    />
                  </template>
                  not available for ground
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item
        :key="2"
      >
        <v-row>
          <v-col>
            <v-data-table
              ref="summaryroute"
              :options.sync="mainRouteMapTable.options"
              :footer-props="{ itemsPerPageOptions: [25, 50, 100, 200 -1] }"
              :headers="mainRouteMapTableHeaders"
              :items="customerRoutesWithGeotagsWithoutRoutes"
              loading-text="Loading... Please wait"
            >
              <template v-slot:body.prepend>
                <tr class="filter-row">
                  <td>
                    <v-autocomplete
                      ref="routeselect"
                      v-model="mainRouteMapTable.filters.routeNames"
                      :items="routeNamesList"
                      label="routes destinations"
                      clearable
                      multiple
                      dense
                      chips
                      small-chips
                      deletable-chips
                      :menu-props="{
                          closeOnContentClick: true,
                        }"
                    />
                  </td>
                  <td>
                    <v-text-field
                      :value="mainRouteMapTable.filters.placenameIds"
                      @input="v=>mainRouteMapTable.filters.placenameIds=v"
                      type="text"
                      label="placenames IDs"
                      clearable
                      dense
                    />
                  </td>
                  <td>
                    <v-autocomplete
                      ref="geotagselect"
                      v-model="mainRouteMapTable.filters.geotagNames"
                      :items="geotagNamesList"
                      label="geotags"
                      clearable
                      multiple
                      dense
                      chips
                      small-chips
                      deletable-chips
                      :menu-props="{
                          closeOnContentClick: true,
                        }"
                    />
                  </td>
                  <td>
                    <v-text-field
                      :value="mainRouteMapTable.filters.tripbitIds"
                      @input="v=>mainRouteMapTable.filters.tripbitIds=v"
                      type="text"
                      label="tripbits IDs"
                      clearable
                      dense
                    />
                  </td>
                  <td>
                    <v-autocomplete
                      ref="iataselect"
                      v-model="mainRouteMapTable.filters.iata"
                      :items="iataList"
                      label="iata"
                      clearable
                      multiple
                      dense
                      chips
                      small-chips
                      deletable-chips
                      :menu-props="{
                          closeOnContentClick: true,
                        }"
                    />
                    <v-autocomplete
                      ref="icaoselect"
                      v-model="mainRouteMapTable.filters.icao"
                      :items="icaoList"
                      label="icao"
                      clearable
                      multiple
                      dense
                      chips
                      small-chips
                      deletable-chips
                      :menu-props="{
                          closeOnContentClick: true,
                        }"
                    />
                  </td>
                  <td/>
                </tr>
              </template>

              <template v-slot:item.route_name="{ item }">
                {{ item.is_without_route ? '' : item.route_name }}
              </template>

              <template v-slot:item.pn_ids="{ item }">
                {{ item.pn_ids.join(', ') }}
              </template>
              <template v-slot:item.iata_icao="{ item }">
                <div v-for="(el, index) in item.iata_icao" :key="index">
                  {{ el }}
                </div>
              </template>
              <template v-slot:item.geotags="{ item }">
                <div v-for="(el, index) in item.geotags" :key="index">
                  {{ el }}
                </div>
              </template>
              <template v-slot:item.tb_ids="{ item }">
                {{ item.tb_ids.join(', ') }}
              </template>

            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import XlsxExport from '../components/XlsxExport.vue';
import TripleButton from '../components/TripleButton.vue';
import Chart from '../components/ChartLinear.vue';
import GoogleMapMarkers from '../components/GoogleMapMarkers.vue';
import SimpleList from '../components/SimpleList.vue';
import routeSvg from '../../public/img/icon-route.svg';
// eslint-disable-next-line max-len
import GreyBarWithTitleAndValue from '../components/microcomponents/GreyBarWithTitleAndValue.vue';

export default {
  name: 'DestinationAnalytics',
  components: {
    TripleButton,
    Chart,
    GoogleMapMarkers,
    SimpleList,
    routeSvg,
    GreyBarWithTitleAndValue,
    XlsxExport,
  },
  data: () => ({
    isFiltersChanged: true,
    tripbitsCustomerList: [],
    currentTripbitsCustomer: {
      id: null,
      name: '',
    },

    tripbitsCustomers: [],

    customerHeadBars: [
      { title: 'Number of Destinations', value: null },
      { title: 'Number of Flights Month', value: null },
      { title: 'Number of POI\'s (experiences)', value: null },
      { title: 'Number of Reels', value: null },
    ],
    customerRoutes: [],
    customerRoutesWithGeotagsWithoutRoutes: [],

    geotagDestinationDialog: {
      isOpened: false,
      geotagId: null,
      geotagName: '',
      placenamesIds: [],
      routeName: [],
      iataIcao: [],
    },

    table: {
      loading: true,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['id'],
        sortDesc: [false],
        mustSort: true,
        multiSort: false,
      },
    },

    routeMapTable: {
      selectedRatingOptions: [],
      itemsWithoutTotal: [],
      expanded: [],
      headers: [
        { text: 'Category', value: 'category' },
        { text: '#Destinations ', value: 'routemap_amount' },
        { text: 'Flights/Month', value: 'flights_month_amount' },
        { text: '#Guides', value: 'tripbits_amount' },
        { text: 'Coverage', value: 'coverage' },
      ],
      isShowMaintenanceHeaders: false,
      expandHeaders: [
        { text: 'Destination', value: 'route_name', isForMaitenance: false },
        { text: 'Destination code', value: 'iata_icao', isForMaitenance: false },
        { text: 'Route flights (month avg)', value: 'route_flights_amount', isForMaitenance: false },
        { text: 'Number POI\'s', value: 'pois_amount', isForMaitenance: false },
        { text: 'Number of Reels', value: 'reels_amount', isForMaitenance: false },
        { text: 'Geotags', value: 'geotags', isForMaitenance: true },
        { text: 'Placenames IDs', value: 'pn_ids', isForMaitenance: true },
        { text: 'Tripbits ID', value: 'tb_ids', isForMaitenance: true },
      ],
    },

    mainRouteMapTable: {
      loading: true,
      items: [],
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: ['route_flights_amount'],
        sortDesc: [true],
        mustSort: true,
        multiSort: false,
      },
      filters: {
        routeNames: [],
        placenameIds: '',
        geotagNames: [],
        tripbitIds: '',
        iata: [],
        icao: [],
      },
    },
    geotagFilters: {
      id: null,
      name: '',
      pois_count: null,
      status: null,
      is_customer_popular: null,
    },
    totalGeotags: 0,
    chartsData: {
      overview1: {},
    },
    currentCustomerTab: 0,
    geotagCharts: {},
    chartColor: 'rgba(54, 162, 235, 1)',
    chartOptions: {
      legend: {
        display: true,
      },
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
          },
        }],
      },
      tooltips: {
        callbacks: {
          label(tooltipItem) {
            return tooltipItem.value;
          },
        },
      },
    },
    geotagsTotal: [],
    customerContent: {
      poisTotalAmount: 0,
      videosTotalAmount: 0,
      playlistRating: [],
      providerRating: [],
      hashtagRating: [],
      hashtagGeotagRating: [],
      allVideoDuration: false,
      commonVideoDuration: false,
      reviewsTotalAmount: 0,
      poiHashtagsTotalAmount: 0,
      geotagHashtagsTotalAmount: 0,
    },
  }),

  created() {
    this.initPage();
  },

  methods: {
    ...mapActions({
      setSnack: 'snackbar/setState',
    }),

    async initPage() {
      await this.fetchTripbitsCustomerList();

      this.currentTripbitsCustomer = this.tripbitsCustomerList.length
        ? this.tripbitsCustomerList[0]
        : {
          id: null,
          name: '',
        };

      if (this.hasAllAbilities([this.$constants.ABILITY.CUSTOMER])) {
        await this.getCustomerStatisticsData();
      }
    },

    setMultipleChartData(labels, data, label, color = this.barChartColor) {
      return ({
        labels,
        datasets: [{
          label,
          data: data.map((el) => el.value),
          borderColor: color,
          borderWidth: 1,
        }],
      });
    },

    secondsToHourMinSecFormat(seconds) {
      if (seconds === false || seconds === null) {
        return '00:00:00';
      }
      const hours = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds - hours * 3600) / 60);
      const sec = seconds % 60;
      return `${hours < 10 ? '0' : ''}${hours}:${mins < 10 ? '0' : ''}${mins}:${sec < 10 ? '0' : ''}${sec}`;
    },

    rowClick(item, slot) {
      slot.expand(!slot.isExpanded);
    },

    getRoutesForRatingOption(ratingOption) {
      if (ratingOption === 'total') {
        return this.customerRoutesWithGeotagsWithoutRoutes;
      }

      return this.customerRoutesWithGeotagsWithoutRoutes
        .filter((el) => el.rating_list.includes(ratingOption))
        .sort((a, b) => b.route_flights_amount - a.route_flights_amount);
    },

    getRoutesForTotalOptions() {
      return this.customerRoutesWithGeotagsWithoutRoutes
        .filter((el) => el.is_selected);
    },

    openGeotagDestinationDialog(geotagId, geotagName) {
      this.resetGeotagDestinationDialog();
      const destinations = this.getGeotagRoutemapDestinations(geotagId);

      if (destinations.length) {
        Object.assign(
          this.geotagDestinationDialog,
          {
            geotagId,
            geotagName,
            placenamesIds: [...new Set(destinations.map((el) => el.pn_ids).flat(2))],
            routeName: destinations.map((el) => el.route_name),
            iataIcao: [...new Set(destinations.map((el) => el.iata_icao).flat(2))],
          },
        );
        this.geotagDestinationDialog.isOpened = true;
      }
    },

    closeGeotagDestinationDialog() {
      this.resetGeotagDestinationDialog();
      this.geotagDestinationDialog.isOpened = false;
    },

    resetGeotagDestinationDialog() {
      Object.assign(
        this.geotagDestinationDialog,
        {
          geotagId: null,
          geotagName: '',
          placenamesIds: [],
          routeName: [],
          iataIcao: [],
        },
      );
    },

    getGeotagRoutemapDestinations(geotagId) {
      return this.customerRoutes.filter(
        (el) => el.tb_ids.includes(geotagId),
      );
    },

    updateSelectedRoutes(value, selectedItem) {
      const routeIndexToChange = this.customerRoutesWithGeotagsWithoutRoutes
        .findIndex((el) => el.id === selectedItem.id);

      this.customerRoutesWithGeotagsWithoutRoutes[routeIndexToChange].is_selected = value;
    },

    checkIfAllRoutesForRatingOptionSelected(ratingOption) {
      const selectableRatingRoutes = this.getRoutesForRatingOption(ratingOption);

      return selectableRatingRoutes.length === selectableRatingRoutes
        .filter((el) => el.is_selected).length;
    },

    selectAllRatingRoutesToggle(value, ratingOption) {
      for (let i = 0; i < this.customerRoutesWithGeotagsWithoutRoutes.length; i += 1) {
        if (this.customerRoutesWithGeotagsWithoutRoutes[i].rating_list.includes(ratingOption)) {
          this.customerRoutesWithGeotagsWithoutRoutes[i].is_selected = value;
        }
      }
    },

    checkIfRatingOptionSelected(ratingOption) {
      const isRatingOptionSelected = this.checkIfAllRoutesForRatingOptionSelected(ratingOption);

      const ratingOptionWasAlreadySelected = this.routeMapTable
        .selectedRatingOptions.includes(ratingOption);

      if (isRatingOptionSelected && !ratingOptionWasAlreadySelected) {
        this.routeMapTable.selectedRatingOptions.push(ratingOption);
      }
      if (!isRatingOptionSelected && ratingOptionWasAlreadySelected) {
        this.routeMapTable.selectedRatingOptions = this.routeMapTable
          .selectedRatingOptions.filter((el) => el !== ratingOption);
      }

      return isRatingOptionSelected;
    },

    checkIfAllRatingOptionSelected() {
      return this.routeMapTable
        .selectedRatingOptions.length === this.routeMapTableItemsWithTotal.length - 1;
    },

    selectAllRatingsToggle(value) {
      for (let i = 0; i < this.customerRoutesWithGeotagsWithoutRoutes.length; i += 1) {
        this.customerRoutesWithGeotagsWithoutRoutes[i].is_selected = value;
      }
    },

    getRoutesWithGeotagsWithoutRoutes() {
      return [...this.customerRoutes, ...this.geotagsWithoutRoutes];
    },

    getExportSummaryWorkbook() {
      let filteredItems = [];

      if (this.$refs.summaryroute) {
        const items = this.$refs.summaryroute.$children[0].filteredItems;
        const { sortBy } = this.$refs.summaryroute.$children[0].options;
        const { sortDesc } = this.$refs.summaryroute.$children[0].options;
        filteredItems = this.$refs.summaryroute.customSortWithHeaders(items, sortBy, sortDesc);
      } else {
        filteredItems = this.customerRoutesWithGeotagsWithoutRoutes;
      }

      const filename = `${this.currentTripbitsCustomer.name}_db_relations_${Date.now()}.xlsx`;

      return filteredItems.length === 0
        ? {
          filename,
          sheets: [
            {
              name: 'summary',
              data: [{}],
            },
          ],
        }
        : {
          filename,
          sheets: [
            {
              name: 'summary',
              data: filteredItems.map((el) => ({
                route_map_destination: el.is_without_route ? '' : el.route_name,
                placenames_ids: el.pn_ids.join(', '),
                geotags: el.geotags.join(', '),
                tripbits_ids: el.tb_ids.join(', '),
                airports: el.iata_icao.join(', '),
                avg_month_flights: el.route_flights_amount,
              })),
            },
          ],
        };
    },

    changeFilters(flag = true) {
      this.isFiltersChanged = flag;
    },

    async getCustomerStatisticsData() {
      const promise1 = this.fetchTripbitsCustomerInfo();
      const promise2 = this.fetchChartsGeotagsData();
      const promise3 = this.fetchRequestedGeotagsPois();

      Promise.all([promise1, promise2, promise3])
        .catch(() => {
          this.setSnack({
            snack: true,
            color: 'red',
            text: 'error',
          });
        })
        .finally(() => {
          this.isFiltersChanged = false;
        });
    },

    async fetchTripbitsCustomerList() {
      const response = await this.$requestHelpers
        .get('api/admin/customer/getUserTripbitsCustomerList');

      if (!response.data.length) {
        await this.$router.push({ name: 'root' });
      }

      this.tripbitsCustomerList = response.data.map(
        (el) => ({
          id: el.id,
          name: el.name,
          label: el.name,
        }),
      );
    },

    fetchTripbitsCustomerInfo() {
      return this.$requestHelpers
        .get(
          'api/admin/customer/getTripbitsCustomerInfo',
          {
            params: {
              id: this.currentTripbitsCustomer.id,
            },
          },
        )
        .then((response) => {
          this.tripbitsCustomers = response.data.result;
        });
    },

    fetchChartsGeotagsData() {
      return this.$requestHelpers
        .get(
          'api/admin/customer/getCustomerGeotagsChart',
          {
            params: {
              id: this.currentTripbitsCustomer.id,
            },
          },
        ).then((response) => {
          this.geotagCharts = response.data.result;
        });
    },

    fetchRequestedGeotagsPois() {
      return this.$requestHelpers
        .get(
          'api/admin/customer/getCustomerRoutemapGeotagsStatistics',
          {
            params: {
              id: this.currentTripbitsCustomer.id,
            },
          },
        ).then((response) => {
          if (response.data.result.length
              || (typeof response.data.result === 'object' && !!Object.keys(response.data.result).length)) {
            this.geotagsTotal = response.data.result.geotags_total;
            this.customerContent.poisTotalAmount = response.data.result.pois_amount;
            this.customerContent.videosTotalAmount = response.data.result.videos_amount;
            this.customerContent.playlistRating = response.data.result.top_playlists
              .filter((el) => el.value > 0);
            this.customerContent.providerRating = response.data.result.top_providers
              .filter((el) => el.value > 0);
            this.customerContent.hashtagRating = response.data.result.top_hashtags
              .filter((el) => el.value > 0);
            this.customerContent.hashtagGeotagRating = response.data.result.top_hashtags_geotags
              .filter((el) => el.value > 0);
            this.customerContent.allVideoDuration = response.data.result.all_video_duration;
            this.customerContent.commonVideoDuration = response.data.result.common_video_duration;
            this.routeMapTable.itemsWithoutTotal = response.data.result.destinations_rating;

            this.customerContent
              .reviewsTotalAmount = response.data.result.total_provider_reviews_amount;
            this.customerContent
              .poiHashtagsTotalAmount = response.data.result.total_poi_hashtag_amount;
            this.customerContent
              .geotagHashtagsTotalAmount = response.data.result.total_geotag_hashtag_amount;

            this.customerRoutes = response.data.result.routes;
          } else {
            this.resetGeotagsAndRoutes();
          }

          this.customerRoutesWithGeotagsWithoutRoutes = this.getRoutesWithGeotagsWithoutRoutes();

          this.customerHeadBars[0].value = [...new Set(this.getRoutesWithGeotagsWithoutRoutes()
            .filter((el) => el.pn_ids.length))].length;
          this.customerHeadBars[1].value = this.customerRoutes
            .reduce((accum, el) => accum + el.route_flights_amount, 0);
          this.customerHeadBars[2].value = this.customerContent.poisTotalAmount;
          this.customerHeadBars[3].value = this.customerContent.videosTotalAmount;
        });
    },

    resetGeotagsAndRoutes() {
      this.geotagsTotal = [];
      this.customerContent = {
        poisTotalAmount: 0,
        videosTotalAmount: 0,
        playlistRating: [],
        providerRating: [],
        hashtagRating: [],
        hashtagGeotagRating: [],
        allVideoDuration: false,
        commonVideoDuration: false,
        reviewsTotalAmount: 0,
        poiHashtagsTotalAmount: 0,
        geotagHashtagsTotalAmount: 0,
      };
      this.routeMapTable.itemsWithoutTotal = [];
    },

  },

  computed: {

    routeNamesList() {
      const diversedNamesList = this.customerRoutes.map(
        (item) => item.route_name.split('/'),
      ).flat().sort();

      diversedNamesList.unshift('<empty>');

      return [...new Set(diversedNamesList)];
    },

    geotagNamesList() {
      const diversedNamesList = this.geotagsTotal.map(
        (item) => item.name,
      ).flat().sort();

      diversedNamesList.unshift('<empty>');

      return [...new Set(diversedNamesList)];
    },

    iataList() {
      if (!this.customerRoutes) return [];

      return [...new Set(
        this.customerRoutes.map(
          (el) => el.iata_icao.map(
            (iataIcao) => iataIcao.split('/').filter((code) => code.length === 3),
          ),
        ).flat(2).sort(),
      )];
    },

    icaoList() {
      if (!this.customerRoutes) return [];

      return [...new Set(
        this.customerRoutes.map(
          (el) => el.iata_icao.map(
            (iataIcao) => iataIcao.split('/').filter((code) => code.length === 4),
          ),
        ).flat(2).sort(),
      )];
    },

    mainRouteMapTableHeaders() {
      return [
        {
          text: 'Route Map Destination',
          value: 'route_name',
          filter: (value, search, item) => {
            if (this.mainRouteMapTable.filters.routeNames.length === 0) {
              return true;
            }

            const isMatchedEmpty = this.mainRouteMapTable.filters.routeNames.includes('<empty>')
            && item.is_without_route;

            return isMatchedEmpty || this.mainRouteMapTable.filters.routeNames.some(
              (el) => (`${value}`).split('/').includes(el),
            );
          },
        },
        {
          text: 'Placenames IDs',
          value: 'pn_ids',
          filter: (value) => {
            if (!this.mainRouteMapTable.filters.placenameIds) return true;
            const list = this.mainRouteMapTable.filters.placenameIds
              .replaceAll(',', ' ').split(' ').map((item) => parseInt(item, 10));

            return value.some((el) => list.includes(el));
          },
        },
        {
          text: 'Geotags',
          value: 'geotags',
          filter: (value) => {
            if (this.mainRouteMapTable.filters.geotagNames.length === 0) {
              return true;
            }

            const isMatchedEmpty = this.mainRouteMapTable.filters.geotagNames.includes('<empty>')
              && value.length === 0;

            return isMatchedEmpty || this.mainRouteMapTable.filters.geotagNames.some(
              (el) => value.includes(el),
            );
          },
        },
        {
          text: 'Tripbits ID',
          value: 'tb_ids',
          filter: (value) => {
            if (!this.mainRouteMapTable.filters.tripbitIds) return true;
            const list = this.mainRouteMapTable.filters.tripbitIds
              .replaceAll(',', ' ').split(' ').map((item) => parseInt(item, 10));

            return value.some((el) => list.includes(el));
          },
        },
        {
          text: 'Destinations',
          value: 'iata_icao',
          filter: (value) => {
            const isIataFilterApplied = this.mainRouteMapTable.filters.iata.length !== 0;
            const isIcaoFilterApplied = this.mainRouteMapTable.filters.icao.length !== 0;

            if (!isIataFilterApplied && !isIcaoFilterApplied) {
              return true;
            }

            const iata = [
              ...new Set(
                value.map(
                  (el) => el.split('/').filter(
                    (code) => code.length === 3,
                  ),
                ).flat(),
              ),
            ];
            const icao = [
              ...new Set(
                value.map(
                  (el) => el.split('/').filter(
                    (code) => code.length === 4,
                  ),
                ).flat(),
              ),
            ];

            const isIataMatch = !isIataFilterApplied
              ? true
              : this.mainRouteMapTable.filters.iata.some((el) => iata.includes(el));

            const isIcaoMatch = !isIcaoFilterApplied
              ? true
              : icao.length !== 0
              && this.mainRouteMapTable.filters.icao.some((el) => icao.includes(el));

            return isIataMatch && isIcaoMatch;
          },
        },
        {
          text: 'Route flights (month avg)',
          value: 'route_flights_amount',
        },
      ];
    },

    geotagsTableHeaders() {
      return [
        {
          text: 'id',
          value: 'id',
          filter: (value) => {
            if (!this.geotagFilters.id) return true;

            return value === parseInt(this.geotagFilters.id, 10);
          },
        },
        {
          text: 'geotag',
          value: 'name',
          filter: (value) => {
            if (!this.geotagFilters.name) return true;

            return (`${value}`).toLowerCase().includes(this.geotagFilters.name.toLocaleLowerCase());
          },
        },
        {
          text: 'pois amount',
          value: 'pois_count',
          filter: (value) => {
            if (!this.geotagFilters.pois_count) return true;

            return value === parseInt(this.geotagFilters.pois_count, 10);
          },
        },
        {
          text: 'status',
          value: 'status',
          filter: (value) => {
            if (!this.geotagFilters.status) return true;

            let filterValue;
            switch (this.geotagFilters.status) {
              case 'off':
                filterValue = [0];
                break;
              case 'prev only':
                filterValue = [1];
                break;
              case 'prev':
                filterValue = [1, 2];
                break;
              case 'live':
                filterValue = [2];
                break;
              default:
                filterValue = [];
            }

            return filterValue.includes(value);
          },
        },
        {
          text: 'popular',
          value: 'popular',
          filter: (value, search, item) => {
            if (!this.geotagFilters.is_customer_popular) return true;

            switch (this.geotagFilters.is_customer_popular) {
              case 'on':
                return item.customers[0].is_popular === 1;
              case 'off':
                return item.customers[0].is_popular === 0;
              default:
                return true;
            }
          },
        },
      ];
    },

    geotagsWithoutRoutes() {
      const geotagsWithoutRoutes = [];
      for (let i = 0; i < this.geotagsTotal.length; i += 1) {
        if (this.customerRoutes.find(
          (el) => el.tb_ids.includes(this.geotagsTotal[i].id),
        ) === undefined) {
          geotagsWithoutRoutes.push({
            id: this.customerRoutes.length + geotagsWithoutRoutes.length,
            route_name: this.geotagsTotal[i].placenames_name,
            pn_ids: this.geotagsTotal[i].placenames_id ? [this.geotagsTotal[i].placenames_id] : [],
            route_flights_amount: 0,
            rating_list: ['no_flights'],
            iata_icao: [],
            geotags: [this.geotagsTotal[i].name],
            tb_ids: [this.geotagsTotal[i].id],
            pois_amount: this.geotagsTotal[i].pois_enabled_count,
            reels_amount: this.geotagsTotal[i].videodata_count,
            is_selected: false,
            is_live: [this.geotagsTotal[i].status === 2],
            is_without_route: true,
          });
        }
      }

      return geotagsWithoutRoutes;
    },

    isGeotagRoutemapDestination() {
      return (geotagId) => this.customerRoutes.find(
        (el) => el.tb_ids.includes(geotagId),
      ) !== undefined;
    },

    routeMapTableExpandHeaders() {
      return this.routeMapTable.isShowMaintenanceHeaders
        ? this.routeMapTable.expandHeaders
        : this.routeMapTable.expandHeaders.filter((el) => !el.isForMaitenance);
    },

    geotagsOnMap() {
      const selectedRoutes = this.customerRoutesWithGeotagsWithoutRoutes
        .filter((el) => el.is_selected);
      const geotagIdsInSelectedRoutes = [...new Set(selectedRoutes.map((el) => el.tb_ids).flat())];

      return this.geotagsTotal
        .filter((el) => geotagIdsInSelectedRoutes.includes(el.id));
    },

    noFlightsRatingOption() {
      const { geotagsWithoutRoutes } = this;
      const tripbitsAmount = geotagsWithoutRoutes
        .reduce((acc, el) => (el.tb_ids.length && el.is_live[0] ? acc + 1 : acc), 0);
      const routemapAmount = geotagsWithoutRoutes
        .reduce((acc, el) => (el.pn_ids.length ? acc + 1 : acc), 0);

      return ({
        rating_option: 'no_flights',
        manual_name: 'No flights',
        route_based_name: '',
        tripbits_live: 0,
        tripbits_amount: tripbitsAmount,
        tripbits_multi: 0,
        routemap_amount: routemapAmount,
        coverage: 0,
        flights_month_amount: geotagsWithoutRoutes
          .reduce((acc, el) => acc + el.route_flights_amount, 0),
      });
    },

    totalRatingOption() {
      const selectedRoutes = this.customerRoutesWithGeotagsWithoutRoutes
        .filter((el) => el.is_selected);
      const tripbitsAmount = selectedRoutes
        .reduce(
          (acc, el) => (el.tb_ids.length && el.is_live.reduce(
            (sum, el2) => sum || el2,
            false,
          ) ? acc + 1 : acc),
          0,
        );
      const routemapAmount = selectedRoutes
        .reduce((acc, el) => (el.pn_ids.length ? acc + 1 : acc), 0);

      return ({
        rating_option: 'total',
        manual_name: 'Total',
        route_based_name: '',
        tripbits_live: 0,
        tripbits_amount: tripbitsAmount,
        tripbits_multi: 0,
        routemap_amount: routemapAmount,
        coverage: routemapAmount === 0
          ? 0
          : ((100 * tripbitsAmount) / routemapAmount).toFixed(1),
        flights_month_amount: selectedRoutes.reduce((acc, el) => acc + el.route_flights_amount, 0),
      });
    },

    routeMapTableItemsWithTotal() {
      return [
        ...this.routeMapTable.itemsWithoutTotal,
        this.noFlightsRatingOption,
        this.totalRatingOption,
      ];
    },

    customerTabs() {
      return this.hasAllAbilities([this.$constants.ABILITY.CUSTOMER])
        ? [
          'Content',
        ]
        : [
          'Content',
          'Summary',
          'DB relations',
        ];
    },
  },
};
</script>
