<template>
  <v-btn-toggle
    :value="selectedButtons"
    multiple
    dense
  >
    <v-btn
      v-for="(button) in buttons"
      :key="button.key"
      :active-class="button.activeClass"
      :class="{'custom-disabled': disabled}"
      @click="getClicked(button.key)"
    >
      {{ button.value }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>

export default {
  name: 'TripleButton',
  props: {
    status: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    buttons: [
      {
        key: 0,
        value: 'off',
        activeClass: 'grey darken-4 grey--text text--lighten-4',
      },
      {
        key: 1,
        value: 'prev',
        activeClass: 'green darken-4 green--text text--lighten-4',
      },
      {
        key: 2,
        value: 'live',
        activeClass: 'green darken-4 green--text text--lighten-4',
      },
    ],
    selectedButtons: [],
  }),

  methods: {
    statusToSelectedButtonsArray(status) {
      if (status === 2) {
        return [1, 2];
      }

      return [status];
    },
    getClicked(status) {
      this.selectedButtons = this.statusToSelectedButtonsArray(status);
      this.$emit('pressed', status);
    },
  },

  mounted() {
    this.selectedButtons = this.statusToSelectedButtonsArray(this.status);
  },

  watch: {
    status(newVal) {
      this.selectedButtons = this.statusToSelectedButtonsArray(newVal);
    },
  },
};
</script>

<style lang="css" scoped>
.custom-disabled {
  pointer-events: none;
  filter: opacity(50%);
}
</style>
